
import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ja";

export default Vue.extend({
  name: "DatePager",

  props: {
    value: {
      type: String,
      required: false,
    },
    month: {
      type: String,
      required: false,
    },
  },

  mounted() {
    dayjs.locale("ja");
  },

  watch: {},

  data() {
    return {
      formatString: "YYYY年MM月",
      open: false,
    };
  },

  computed: {
    formattedDate(): string {
      if (this.value) {
        return dayjs(this.value).format(this.formatString);
      }
      return dayjs(this.value).format(this.formatString);
    },
  },

  methods: {
    add() {
      if (this.value) {
        const newValue = dayjs(this.value).add(1, "month").format("YYYY-MM");
        this.$emit("input", newValue);
        return;
      }
    },
    subtract() {
      if (this.value) {
        const newValue = dayjs(this.value).subtract(1, "month").format("YYYY-MM");
        this.$emit("input", newValue);
        return;
      }
    },
  },
});
