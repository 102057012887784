import LocalDataService from "@/service/LocalDataService";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import ApiInvoice from "@/api/ApiInvoice";
import { OperationInvoiceResponse } from "chaild-api/lib/index";

export interface InvoiceState {
  invoices: OperationInvoiceResponse[];

  // import
  importId: number | null;
  importStatus: string | null;

  // export
  exportId: number | null;
  exportStatus: string | null;
}

@Module({ dynamic: true, store, name: "invoice", namespaced: true })
class InvoiceModule extends VuexModule implements InvoiceState {
  public invoices: OperationInvoiceResponse[] = [];
  public exportId: number | null = null;
  public exportStatus: string | null = null;
  public importId: number | null = null;
  public importStatus: string | null = null;

  public get impotarble() {
    const salesSettleds = this.invoices.filter((invoice) => invoice.status === "salesSettled");
    if (salesSettleds.length === this.invoices.length) {
      return true;
    }
    return false;
  }

  public get exportable() {
    const exportables = this.invoices.filter(
      (invoice) => invoice.status === "salesSettled" || invoice.status === "invoiceSettled"
    );
    if (exportables.length === this.invoices.length) {
      return true;
    }
    return false;
  }

  @Action({ rawError: true })
  public async listInvoices({ year, month }: { year: string | number; month: string | number }) {
    const company = LocalDataService.getCompany();
    if (company) {
      try {
        const response = await ApiInvoice.listInvoices({
          companyId: company.companyId,
          year,
          month,
        });
        this.setInvoices(response);
        return response;
      } catch {
        return null;
      }
    }
  }

  @Mutation
  public setInvoices(invoices: OperationInvoiceResponse[]) {
    this.invoices = invoices;
  }

  // import
  @Action
  public async importRecievables({
    companyId,
    year,
    month,
    filePath,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
    filePath: string;
  }) {
    try {
      const response = await ApiInvoice.importRecievables({
        companyId,
        year,
        month,
        filePath,
      });
      if (response) {
        this.setImportId(response.importStatusId);
      }
    } catch {
      return;
    }
  }

  @Mutation
  public setImportId(importId: number) {
    this.importId = importId;
    this.importStatus = null;
  }

  @Action
  public async checkImportStatus() {
    if (this.importId) {
      try {
        const response = await ApiInvoice.getUploadStatus({ importStatusId: this.importId });
        if (response) {
          this.setUploadStatus(response.status);
        }
      } catch {
        return;
      }
    }
  }

  @Mutation
  public setUploadStatus(status: string) {
    this.importStatus = status;
  }

  // export
  @Action
  public async exportSales({
    companyId,
    year,
    month,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiInvoice.exportSales({
        companyId,
        year,
        month,
      });
      if (response) {
        this.setExportId(response.exportStatusId);
      }
    } catch (e) {
      return;
    }
  }

  @Mutation
  public setExportId(exportId: number | null) {
    this.exportId = exportId;
    this.exportStatus = null;
  }

  @Action
  public async checkExportStatus() {
    if (this.exportId) {
      try {
        const response = await ApiInvoice.getExportStatus({ exportStatusId: this.exportId });
        if (response) {
          this.setExportStatus(response.status);
          if (response.url) {
            window.open(response.url);
          }
        }
      } catch {
        return;
      }
    }
  }

  @Mutation
  public setExportStatus(status: string) {
    this.exportStatus = status;
  }
}

export const invoiceModule = getModule(InvoiceModule);
