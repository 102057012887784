
import Vue from "vue";

import { schoolInvoiceModule } from "@/store/viewModule/schoolInvoiceModule";
import dayjs from "dayjs";
import { OperationChildInvoiceResponse } from "chaild-api/lib";
import { ExtraServiceDetailType } from "chaild-api/src/model/mysql/entity/invoiceEntity";
import ApiInvoice from "@/api/ApiInvoice";
import { PaymentMethodCategoryType } from "chaild-api/src/constant/paymentMethodCategory";
import CommonUtil from "@/utils/CommonUtils";
import { invoiceStatusItems, paymentMethodItems } from "@/constants/invoice";

interface DataType {
  nurseryId: number | null;
  yearMonth: string | null;
  isFetching: boolean;
  expanded: Array<any>;
  headers: Array<any>;
  tableData: TableData[];
  invoiceStatusOptions: InvoiceStatusOption[];
  invoiceStatus: string | null;
  paymentMethodItems: any[];
}

interface InvoiceStatusOption {
  value: string;
  label: string;
}

interface TableData {
  childId: number;
  childName: string;
  className: string;
  collectionMethod: PaymentMethodCategoryType | null;
  monthlyNurseryFee: number;
  monthlyNurseryFeeDiff: number;
  tempNurseryFee: number;
  tempNurseryFeeDiff: number;
  monthlyAdditionalFee: number;
  monthlyAdditionalFeeDiff: number;
  spotAdditionalFee: number;
  spotAdditionalFeeDiff: number;
  foodServiceFee: number;
  foodServiceFeeDiff: number;
  extraServiceFee: number;
  extraServiceFeeDiff: number;
  accountsRecievable: number;
  totalSales: number;
  totalAmount: number;
  extraServices: ExtraServiceDetailType[];
}

export default Vue.extend({
  data: (): DataType => ({
    nurseryId: null,
    yearMonth: dayjs().format("YYYY-MM"),
    isFetching: false,
    expanded: [],
    headers: [
      { text: "園児氏名", value: "childName" },
      { text: "クラス名", value: "className" },
      { text: "集金方法", value: "collectionMethod" },
      { text: "売掛金", value: "accountsRecievable" },
      { text: "売上総額", value: "totalSales" },
      { text: "請求総額", value: "totalAmount" },
      { text: "", value: "childId" },
      { text: "", value: "data-table-expand" },
    ],
    invoiceStatusOptions: invoiceStatusItems,
    // invoiceStatusOptions: [
    //   { value: 'applied', label: '施設入力完了' },
    //   { value: 'salesSettled', label: '売上確定' },
    //   { value: 'invoiceSettled', label: '請求確定' },
    // ],
    invoiceStatus: null,
    tableData: [],
    paymentMethodItems: paymentMethodItems,
  }),
  computed: {
    schoolInvoice() {
      return schoolInvoiceModule.invoiceDetail;
    },
    currentInvoiceStatus(): string | null {
      if (this.schoolInvoice) {
        return this.schoolInvoice.status;
      }
      return null;
    },
    invoices(): OperationChildInvoiceResponse[] {
      if (this.schoolInvoice) {
        return this.schoolInvoice.invoices;
      }
      return [];
    },
    schoolName(): string {
      if (this.schoolInvoice) {
        return this.schoolInvoice.nurseryName;
      }
      return "";
    },
    year(): number {
      const year = parseInt(dayjs(this.yearMonth).format("YYYY"));
      if (this.month < 4) {
        return year - 1;
      }
      return year;
    },
    month(): number {
      return parseInt(dayjs(this.yearMonth).format("MM"));
    },
    importStatus(): string | null {
      if (schoolInvoiceModule.importStatus) {
        if (schoolInvoiceModule.importStatus === "inProgress") {
          return "進行中";
        }
        if (schoolInvoiceModule.importStatus === "failed") {
          return "取り込み失敗";
        }
        if (schoolInvoiceModule.importStatus === "success") {
          return "取り込み完了";
        }
      }
      if (schoolInvoiceModule.uploadId) {
        return "進行中";
      }
      return null;
    },
    exportStatus(): string | null {
      if (schoolInvoiceModule.exportStatus) {
        if (schoolInvoiceModule.exportStatus === "inProgress") {
          return "進行中";
        }
        if (schoolInvoiceModule.exportStatus === "failed") {
          return "書き出し失敗";
        }
        if (schoolInvoiceModule.exportStatus === "success") {
          return "書き出し完了";
        }
      }
      if (schoolInvoiceModule.uploadId) {
        return "進行中";
      }
      return null;
    },
  },
  methods: {
    async getSchoolInvoice() {
      if (this.nurseryId && this.yearMonth) {
        const year = CommonUtil.getFiscalYear(this.yearMonth);
        const month = dayjs(this.yearMonth).month() + 1;

        this.isFetching = true;
        await Promise.all([
          schoolInvoiceModule.getSchoolInvoice({
            nurseryId: this.nurseryId,
            year,
            month,
          }),
        ]).catch();
        this.isFetching = false;
      }
    },
    async saveRecord(item: TableData) {
      if (this.yearMonth) {
        const year = CommonUtil.getFiscalYear(this.yearMonth);
        const month = dayjs(this.yearMonth).month() + 1;
        this.isFetching = true;
        await schoolInvoiceModule.updateChildRecrod({
          childId: item.childId,
          year: String(year),
          month: String(month),
          monthlyAdditionalFeeDiff: parseInt(`${item.monthlyAdditionalFeeDiff}`, 10),
          tempNurseryFeeDiff: parseInt(`${item.tempNurseryFeeDiff}`, 10),
          monthlyNurseryFeeDiff: parseInt(`${item.monthlyNurseryFeeDiff}`, 10),
          spotAdditionalFeeDiff: parseInt(`${item.spotAdditionalFeeDiff}`, 10),
          foodServiceFeeDiff: parseInt(`${item.foodServiceFeeDiff}`, 10),
          extraServiceDiffs: item.extraServices.map((es) => ({
            extraServiceBillingDetailId: es.extraServiceBillingDetailId,
            adjustmentAmount: parseInt(`${es.adjustmentAmount}`, 10),
          })),
        });
        this.isFetching = false;
      }
    },
    async updateStatus() {
      if (this.invoiceStatus) {
        this.isFetching = true;
        if (this.nurseryId) {
          let nurseryIds: number[] = [];
          nurseryIds.push(Number(this.nurseryId));
          const response = await schoolInvoiceModule
            .updateStatus({
              nurseryIds: nurseryIds,
              year: this.year,
              month: this.month,
              status: this.invoiceStatus,
            })
            .catch(() => (this.isFetching = false));
          if (response) {
            alert("確定しました");
            await this.getSchoolInvoice();
          }
        }
        this.isFetching = false;
      }
    },
    calcTableData() {
      this.tableData = this.invoices.map((i) => {
        const childName = `${i.child.lastName} ${i.child.firstName}`;
        if (!i.invoice) {
          return {
            childId: i.child.childId,
            childName,
            className: "",
            collectionMethod: null,
            monthlyNurseryFee: 0,
            monthlyNurseryFeeDiff: 0,
            tempNurseryFee: 0,
            tempNurseryFeeDiff: 0,
            monthlyAdditionalFee: 0,
            monthlyAdditionalFeeDiff: 0,
            spotAdditionalFee: 0,
            spotAdditionalFeeDiff: 0,
            foodServiceFee: 0,
            foodServiceFeeDiff: 0,
            extraServiceFee: 0,
            extraServiceFeeDiff: 0,
            accountsRecievable: 0,
            totalSales: 0,
            totalAmount: 0,
            extraServices: [],
          };
        }
        const monthlyNursery = i.invoice.monthlyNurseryFee + i.invoice.monthlyNurseryFeeDiff;
        const tempNursery = i.invoice.tempNurseryFee + i.invoice.tempNurseryFeeDiff;
        const monthlyAdditional =
          i.invoice.monthlyAdditionalFee + i.invoice.monthlyAdditionalFeeDiff;
        const spotAdditional = i.invoice.spotAdditionalFee + i.invoice.spotAdditionalFeeDiff;
        const foodService = i.invoice.foodServiceFee + i.invoice.foodServiceFeeDiff;
        const extraService = i.invoice.extraServiceFee + i.invoice.extraServiceFeeDiff;

        const totalSales =
          monthlyNursery +
          tempNursery +
          monthlyAdditional +
          spotAdditional +
          foodService +
          extraService;
        const totalAmount = totalSales + i.invoice.accountsReceivable;

        // クラス名探し
        const findClass = i.child.nurseryClasses.find((nc) => nc.year === this.year);
        const className = findClass ? findClass.className : "";

        return {
          childId: i.child.childId,
          childName,
          className: className,
          collectionMethod: i.invoice.paymentMethod || null,
          monthlyNurseryFee: i.invoice.monthlyNurseryFee,
          monthlyNurseryFeeDiff: i.invoice.monthlyNurseryFeeDiff,
          tempNurseryFee: i.invoice.tempNurseryFee,
          tempNurseryFeeDiff: i.invoice.tempNurseryFeeDiff,
          monthlyAdditionalFee: i.invoice.monthlyAdditionalFee,
          monthlyAdditionalFeeDiff: i.invoice.monthlyAdditionalFeeDiff,
          spotAdditionalFee: i.invoice.spotAdditionalFee,
          spotAdditionalFeeDiff: i.invoice.spotAdditionalFeeDiff,
          foodServiceFee: i.invoice.foodServiceFee,
          foodServiceFeeDiff: i.invoice.foodServiceFeeDiff,
          extraServiceFee: i.invoice.extraServiceFee,
          extraServiceFeeDiff: i.invoice.extraServiceFeeDiff,
          accountsRecievable: i.invoice.accountsReceivable,
          totalSales: totalSales,
          totalAmount: totalAmount,
          extraServices: i.invoice.extraServiceDetails,
        };
      });

      if (this.schoolInvoice) {
        this.invoiceStatus = this.schoolInvoice.status;
      }
    },
    async openInvoice(item: TableData) {
      this.isFetching = true;
      const response = await ApiInvoice.donwloadInvoice({
        childId: item.childId,
        year: this.year,
        month: this.month,
      }).catch(() => {
        this.isFetching = false;
      });
      this.isFetching = false;
      if (response) {
        const url = response.url;
        window.open(url);
      }
    },
    // getPaymentMethodText(value: string): string {
    //   if (value == 'card') { return 'クレジットカード'; }
    //   else if (value == 'withdrawal') { return '口座振替'; }
    //   else if (value == 'invoice') { return '振り込み'; }
    //   else { return '集金'; }
    // },
    getPaymentMethodText(value?: string): string {
      if (!value) {
        return "集金";
      }
      return this.paymentMethodItems.find((p) => p.value == value)?.text || "";
    },
  },
  watch: {
    invoices: {
      handler() {
        this.calcTableData();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const params = this.$route.params;
    if (params.nurseryId && params.yearMonth) {
      try {
        const nurseryId = parseInt(params.nurseryId as string);
        const yearMonth = params.yearMonth as string;
        this.nurseryId = nurseryId;
        this.yearMonth = yearMonth;
        this.getSchoolInvoice();
      } catch {
        return;
      }
    }

    if (this.yearMonth) {
      console.log(CommonUtil.getFiscalYear(this.yearMonth));
    }
  },
});
