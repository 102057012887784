
import Vue from "vue";

import MonthPager from "@/components/atoms/MonthPager.vue";

import { invoiceModule } from "@/store/viewModule/invoicesModule";
import dayjs from "dayjs";
import LocalDataService from "@/service/LocalDataService";
import ApiResource from "@/api/ApiResource";
import CommonUtil from "@/utils/CommonUtils";
import { OperationInvoiceResponse } from "chaild-api/lib";
import { invoiceStatusItems } from "@/constants/invoice";
import { schoolInvoiceModule } from "@/store/viewModule/schoolInvoiceModule";

interface DataType {
  yearMonth: string;
  isFetching: boolean;

  // import
  isProcessingImport: boolean;
  fileUploadModal: boolean;
  fileToUpload: File | null;
  importPollingTimerId: number | null;

  // export
  isProcessingExport: boolean;
  exportPollingTimerId: number | null;

  invoiceStatusOptions: any[];
  tableHeaders: any[];
  selected: OperationInvoiceResponse[];
  invoiceStatus: string | null;
}

export default Vue.extend({
  components: {
    MonthPager,
  },
  computed: {
    invoices() {
      return invoiceModule.invoices;
    },
    importable() {
      return invoiceModule.impotarble;
    },
    exportable() {
      return invoiceModule.exportable;
    },
    year(): number {
      return parseInt(dayjs(this.yearMonth).format("YYYY"));
    },
    month(): number {
      return parseInt(dayjs(this.yearMonth).format("MM"));
    },
    importStatus() {
      return invoiceModule.importStatus;
    },
    exportStatus() {
      return invoiceModule.exportStatus;
    },
  },
  data: (): DataType => ({
    yearMonth: dayjs().subtract(1, "month").format("YYYY-MM"),
    isFetching: false,
    // import
    isProcessingImport: false,
    fileUploadModal: false,
    fileToUpload: null,
    importPollingTimerId: null,
    // export
    isProcessingExport: false,
    exportPollingTimerId: null,

    tableHeaders: [
      { text: "施設名", value: "nurseryName" },
      { text: "保育料", value: "nurseryFee" },
      { text: "延長保育料", value: "nurseryAdditionalFee" },
      { text: "園提供サービス料", value: "nurseryServiceFee" },
      { text: "請求額", value: "total", align: "end" },
      { text: "ステータス", value: "status" },
      { text: "", value: "detail" },
    ],
    selected: [],
    invoiceStatusOptions: invoiceStatusItems,
    invoiceStatus: null,
  }),
  watch: {
    yearMonth: {
      handler() {
        this.$router.replace({ path: `/invoice/${this.yearMonth}` });
        this.listInvoices();
      },
    },
  },
  methods: {
    async listInvoices() {
      const month = dayjs(this.yearMonth).month() + 1;
      this.isFetching = true;
      await invoiceModule.listInvoices({
        year: CommonUtil.getFiscalYear(this.yearMonth),
        month,
      });
      this.isFetching = false;
    },
    async updateStatus() {
      if (this.selected.length > 0 && this.invoiceStatus != null) {
        this.isFetching = true;
        const nurseryIds = this.selected.map((s) => s.nurseryId);
        const response = await schoolInvoiceModule
          .updateStatus({
            nurseryIds: nurseryIds,
            month: dayjs(this.yearMonth).month() + 1,
            year: CommonUtil.getFiscalYear(this.yearMonth),
            status: this.invoiceStatus,
          })
          .catch(() => (this.isFetching = false));
        if (response) {
          alert("確定しました");
          await this.listInvoices();
        }
        this.isFetching = false;
      }
    },
    // import
    async upload() {
      const company = LocalDataService.getCompany();
      if (company && this.fileToUpload) {
        this.isProcessingImport = true;
        try {
          const resourceInfo = await ApiResource.getUploadInfo({
            category: "accountsReceivable",
            filename: this.fileToUpload.name || "",
            companyId: company.companyId,
          });
          const upload = await ApiResource.uploadFile({
            url: resourceInfo.url,
            file: this.fileToUpload,
          });
          if (upload) {
            await invoiceModule.importRecievables({
              companyId: company.companyId,
              year: CommonUtil.getFiscalYear(this.yearMonth),
              month: this.month,
              filePath: resourceInfo.filePath,
            });
            this.importPollingTimerId = setInterval(() => {
              this.pollingUpload();
            }, 10000);
            this.fileUploadModal = false;
          }
        } catch {
          this.isProcessingImport = false;
          alert("アップロード処理でエラーが発生しました。");
          return;
        }
      }
    },
    async pollingUpload() {
      await invoiceModule.checkImportStatus();
      if (this.importStatus === "success") {
        this.listInvoices();
        this.isProcessingImport = false;
        if (this.importPollingTimerId) {
          clearInterval(this.importPollingTimerId);
        }
      }
    },
    // export
    async exportSales() {
      const company = LocalDataService.getCompany();
      if (company) {
        this.isProcessingExport = true;
        await invoiceModule.exportSales({
          companyId: company.companyId,
          year: CommonUtil.getFiscalYear(this.yearMonth),
          month: this.month,
        });
        this.exportPollingTimerId = setInterval(() => {
          this.pollingExport();
        }, 10000);
      }
    },
    async pollingExport() {
      await invoiceModule.checkExportStatus();
      if (this.exportStatus) {
        this.isProcessingExport = false;
        invoiceModule.setExportId(null);

        if (this.exportPollingTimerId) {
          clearInterval(this.exportPollingTimerId);
        }
      }
    },
    getInvoiceStatusText(value: string): string {
      return this.invoiceStatusOptions.find((i) => i.value == value)?.label || "";
    },
  },
  async mounted() {
    if (this.$route.params.yearMonth) {
      this.yearMonth = this.$route.params.yearMonth;
    }
    await this.listInvoices();
  },
});
