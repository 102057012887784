
import { PutServiceSettingRequest } from "@/api/ApiCompany";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";
import { companyModule } from "@/store/dataModule/companyModule";
import Vue from "vue";
export default Vue.extend({
  mixins: [ValidationMixin],

  async mounted() {
    await this.getServiceSetting();
  },

  data() {
    return {
      isLoading: false,
      isFormValid: false,
      serviceCodeLabel: "商品コード",
      taxationLabel: "課税/非課税",
      taxationItems: [
        { text: "課税", value: true },
        { text: "非課税", value: false },
      ],
      inputValue: {} as PutServiceSettingRequest,
    };
  },

  computed: {
    taxRatePercent: {
      get(): number {
        return Number(this.inputValue.taxRate) * 100;
      },
      set(value: number) {
        this.inputValue.taxRate = Number(value) / 100;
      },
    },
  },

  methods: {
    async getServiceSetting() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        const response = await companyModule.getServiceSetting({
          companyId,
        });
        if (response) {
          this.inputValue = response as any;
        }
      }
      this.isLoading = false;
    },
    async updateServiceSetting() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await companyModule.updateServiceSetting({
          companyId,
          ...this.inputValue,
        });
        await this.getServiceSetting();
      }
      this.isLoading = false;
    },
  },
});
