import ApiClient from "@/api/ApiClient";
import { OperationCompanyResponse, ServiceSettingResponse } from "chaild-api/lib";

export interface PutCompanyRequest {
  companyId: string;
  companyName: string;
  postalCode?: string;
  prefecture?: string;
  address?: string;
  building?: string;
  phoneNumber?: string;
  faxNumber?: string;
  representativeUserId: string;
}

export interface PutServiceSettingRequest {
  companyId?: string;
  monthlyNurseryServiceCode?: string; // 月極保育料の商品コード（最大１５桁）
  monthlyAdditionalNurseryServiceCode?: string; // 月極延長料の商品コード（最大１５桁）
  spotNurseryServiceCode?: string; // スポット延長料の商品コード（最大１５桁）
  nurseryFoodServiceCode?: string; // 補食代・主食代の商品コード（最大１５桁）
  extraServiceCode?: string; // 園提供サービスの商品コード（最大１５桁）
  taxRate?: number; // 税率
  monthlyNurseryTaxation?: boolean; // 月極保育料（課税・非課税）
  monthlyAdditionalNurseryTaxation?: boolean; // 月極延長料（課税・非課税）
  spotNurseryTaxation?: boolean; // スポット延長料（課税・非課税）
  nurseryFoodTaxation?: boolean; // 補食代・主食代（課税・非課税）
  extraTaxation?: boolean; // 園提供サービス（課税・非課税）
}

export default class ApiCompany {
  // 企業情報
  public static async getCompany(input: { companyId: string }): Promise<OperationCompanyResponse> {
    const response = await ApiClient.get(`/company/${input.companyId}`, null);
    return response;
  }

  public static async updateCompany(input: PutCompanyRequest): Promise<OperationCompanyResponse> {
    const response = await ApiClient.put(`/company/${input.companyId}`, input);
    return response;
  }

  // 会計情報
  public static async getServiceSetting(input: {
    companyId: string;
  }): Promise<ServiceSettingResponse> {
    const response = await ApiClient.get(`/company/${input.companyId}/service-setting`, null);
    return response;
  }

  public static async updateServiceSetting(
    input: PutServiceSettingRequest
  ): Promise<ServiceSettingResponse> {
    const response = await ApiClient.put(`/company/${input.companyId}/service-setting`, input);
    return response;
  }
}
