
import Vue from "vue";
import LocalDataService from "@/service/LocalDataService";
import { memoruModule } from "@/store/dataModule/memoruModule";
import dayjs from "dayjs";
import { nurseryModule } from "@/store/dataModule/nurseryModule";
import { MemoruPurchaseHistoryListResponse, OperationNurseryResponse } from "chaild-api/lib";

export default Vue.extend({
  name: "MemoruSales",

  async mounted() {
    // fetch nurseries
    await this.listNurseries();

    // search sales
    this.searchFilter.monthTo = dayjs().format("YYYY-MM");
    this.searchFilter.monthFrom = dayjs().subtract(2, "month").format("YYYY-MM");
    if (this.nurseries.length > 0) {
      this.searchFilter.nurseryId = this.nurseries[0].nurseryId;
      await this.listSales();
    }
  },

  data() {
    return {
      loading: false,
      searchFilter: {
        nurseryId: "" as string | number,
        monthTo: "",
        monthFrom: "",
      },
    };
  },

  computed: {
    sales: function (): MemoruPurchaseHistoryListResponse | null {
      return memoruModule.sales;
    },
    nurseries: function (): OperationNurseryResponse[] {
      return nurseryModule.nurseries;
    },
  },

  methods: {
    async listSales() {
      this.loading = true;
      await memoruModule
        .listSales({
          ...this.searchFilter,
        })
        .catch(() => (this.loading = false));
      this.loading = false;
    },
    async listNurseries() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await nurseryModule
          .listNurseries({
            companyId,
          })
          .catch();
      }
      this.loading = false;
    },
  },
});
