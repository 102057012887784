
import Vue from "vue";
import { GuidancePlanFormItemCategoryItems, GuidancePlanTypeItems } from "@/constants/guidance";
import {
  GuidancePlanCategoryType,
  OperationGuidancePlanFormItemMastListResponse,
  OperationGuidancePlanFormMastDetail,
  OperationGuidancePlanFormMastListResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";
import { guidanceModule } from "@/store/dataModule/guidanceModule";
import LocalDataService from "@/service/LocalDataService";
import ApiGuidance from "@/api/ApiGuidance";

type ItemList = {
  itemMastId: number;
  order: number; // orderは送信時に算出する
};

export default Vue.extend({
  name: "GuidancePlanForm",

  async mounted() {
    await this.listForm();
    await this.listFormItem();
  },

  data() {
    return {
      loading: false,
      editDialog: false,
      planItems: GuidancePlanTypeItems,
      categoryItems: GuidancePlanFormItemCategoryItems,
      headers: [
        { text: "テンプレート名称", value: "name" },
        { text: "種類", value: "type" },
        { text: "作成日", value: "createdAt" },
        { text: "編集", value: "edit" },
        { text: "削除", value: "delete" },
      ],
      searchFilters: {
        limit: 2000,
        skip: 0,
        type: "" as GuidancePlanCategoryType,
      },
      input: {
        name: "",
        type: "" as GuidancePlanCategoryType,
        itemMastList: [] as ItemList[],
        formMastId: 0,
      },
    };
  },

  computed: {
    isUpdate: function (): boolean {
      return !!this.input.formMastId;
    },
    /**
     * 帳票
     */
    formResponse: function (): OperationGuidancePlanFormMastListResponse | null {
      return guidanceModule.mastList;
    },

    /**
     * 帳票項目
     */
    itemResponse: function (): OperationGuidancePlanFormItemMastListResponse | null {
      return guidanceModule.itemMastList;
    },
    planFormItems: function (): any[] {
      if (this.itemResponse) {
        return this.itemResponse.data.map((i) => {
          return {
            text: i.name,
            value: i.id,
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    async listForm() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await guidanceModule
          .listMast({
            companyId,
            ...this.searchFilters,
          })
          .catch(() => (this.loading = false));
      }
      this.loading = false;
    },
    async createForm() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        /**
         * orderを計算
         */
        this.input.itemMastList = this.calcItemListOrder();
        const response = await ApiGuidance.createPlanFormMast({
          companyId,
          body: {
            ...this.input,
          },
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listForm();
        }
      }
      this.loading = false;
    },
    async updateForm() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        /**
         * orderを計算
         */
        this.input.itemMastList = this.calcItemListOrder();
        const response = await ApiGuidance.updatePlanFormMast({
          companyId,
          formMastId: this.input.formMastId,
          body: {
            ...this.input,
          },
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listForm();
        }
      }
      this.loading = false;
    },
    async deleteForm(formMastId: number) {
      const answer = confirm("削除しますか");
      if (answer) {
        this.loading = true;
        const companyId = LocalDataService.getCompanyId();
        if (companyId) {
          const response = await ApiGuidance.deletePlanFormMast({
            companyId,
            formMastId,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("削除しました");
            await this.listForm();
          }
        }
      }
      this.loading = false;
    },
    async listFormItem() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await guidanceModule
          .listItemMast({
            companyId,
            ...this.searchFilters,
          })
          .catch(() => (this.loading = false));
      }
      this.loading = false;
    },
    openEditDialog(value?: OperationGuidancePlanFormMastDetail) {
      if (value) {
        this.setInput(value);
      } else {
        this.clearInput();
      }
      this.editDialog = true;
    },
    clearInput() {
      this.input = {
        name: "",
        type: "" as GuidancePlanCategoryType,
        itemMastList: [] as ItemList[],
        formMastId: 0,
      };
    },
    setInput(value: OperationGuidancePlanFormMastDetail) {
      this.input = {
        name: value.name,
        formMastId: value.id,
        type: value.type,
        itemMastList: value.itemMastList.map((item) => {
          return {
            itemMastId: item.itemMast.id,
            order: item.order,
          };
        }),
      };
    },
    /**
     * ItemList.push
     */
    AddItemToItemList() {
      this.input.itemMastList.push({
        itemMastId: 0,
        order: 999,
      });
    },
    /**
     * itemMastList.delete
     */
    deleteItemInItemList(order: number) {
      this.input.itemMastList.splice(order, 1);
    },
    /**
     * calc order
     */
    calcItemListOrder(): ItemList[] {
      return this.input.itemMastList.map((item, index) => {
        return {
          itemMastId: item.itemMastId,
          order: index + 1,
        };
      });
    },
    getPlanText(value: string): string {
      const item = this.planItems.find((p) => p.value == value);
      return item?.text || "";
    },
    getCategryText(value: string): string {
      const item = this.categoryItems.find((c) => c.value == value);
      return item?.text || "";
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
