
import { PutUserRequst } from "@/api/ApiUser";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";
import { userModule } from "@/store/dataModule/userModule";
import { OperationUserResponse } from "chaild-api/lib";
import Vue from "vue";
export default Vue.extend({
  mixins: [ValidationMixin],

  async mounted() {
    if (this.userId) {
      await this.getUser();
    } else {
      this.inputValue.isAdmin = false;
    }
  },

  data() {
    return {
      isLoading: false,
      isFormValid: false,
      inputValue: {} as PutUserRequst,
      genderItems: [
        { text: "男性", value: "male" },
        { text: "女性", value: "female" },
      ],
    };
  },

  computed: {
    userId: function () {
      return this.$route.params.userId;
    },
    user: function (): OperationUserResponse | null {
      return userModule.user;
    },
  },

  methods: {
    async getUser() {
      this.isLoading = true;
      if (this.userId) {
        const response = await userModule.getUser({
          userId: this.userId,
        });
        if (response) {
          this.inputValue = response as any;
        }
      }
      this.isLoading = false;
    },
    async updateUser() {
      this.isLoading = true;
      if (this.userId) {
        if (this.inputValue.building == "") {
          this.inputValue.building = undefined;
        }
        await userModule.updateUser({
          ...this.inputValue,
        });
        this.$router.push({ path: "/admin-user" });
      }
      this.isLoading = false;
    },
    async createUser() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId && this.inputValue) {
        if (this.inputValue.building == "") {
          this.inputValue.building = undefined;
        }
        const response = await userModule
          .createUser({
            companyId,
            ...this.inputValue,
          })
          .catch((e) => {
            alert("不正な値が含まれているか、メールアドレスが既存のユーザーと重複しています");
          });
        if (response) {
          this.$router.push({ path: "/admin-user" });
        }
      }
      this.isLoading = false;
    },
    async deleteUser() {
      const answer = confirm("ユーザーを削除しますか？");
      if (answer && this.userId) {
        this.isLoading = true;
        await userModule.deleteUser({
          userId: this.userId,
        });
        this.$router.push({ path: "/admin-user" });
        this.isLoading = false;
      }
    },
  },
});
