import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import { OperationCompanyResponse, ServiceSettingResponse } from "chaild-api/lib";
import ApiCompany, { PutCompanyRequest, PutServiceSettingRequest } from "@/api/ApiCompany";

export interface CompanyState {
  company: OperationCompanyResponse | null;
  serviceSetting: ServiceSettingResponse | null;
}

@Module({ dynamic: true, store, name: "company", namespaced: true })
class CompanyModule extends VuexModule implements CompanyState {
  public company: OperationCompanyResponse | null = null;
  public serviceSetting: ServiceSettingResponse | null = null;

  @Action({ rawError: true })
  public async getCompany(input: { companyId: string }) {
    const response = await ApiCompany.getCompany(input);
    if (response) {
      this.setCompany(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateCompany(input: PutCompanyRequest) {
    const response = await ApiCompany.updateCompany(input);
    if (response) {
      this.setCompany(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getServiceSetting(input: { companyId: string }) {
    const response = await ApiCompany.getServiceSetting(input);
    if (response) {
      this.setServiceSetting(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateServiceSetting(input: PutServiceSettingRequest) {
    const response = await ApiCompany.updateServiceSetting(input);
    if (response) {
      this.setServiceSetting(response);
      return response;
    }
  }

  @Mutation
  public setCompany(value: OperationCompanyResponse) {
    this.company = value;
  }

  @Mutation
  public setServiceSetting(value: ServiceSettingResponse) {
    this.serviceSetting = value;
  }
}

export const companyModule = getModule(CompanyModule);
