/* tslint:disable:max-classes-per-file */
/* eslint-disable */
import { Authorizer } from "chaild-api/src/common/entity/aws/authorize";
import { MeResponse, OperationCompanyResponse, OperationMeResponse } from "chaild-api/lib/index";

const KEY_ACCESS_TOKEN = "CHAILD_WEB_ACCESS_TOKEN";
const KEY_AUTHORIZER = "CHAILD_WEB_AUTHORIZER";
const KEY_USER = "CHAILD_WEB_USER";
const KEY_NURSERY_ID = "CHAILD_WEB_NURSERY_ID";
const KEY_COMPANY = "CHAILD_WEB_COMPANY";
const KEY_COMPANY_ID = "CHAILD_WEB_COMPANY_ID";

class LocalAuthorizer implements Authorizer {
  public given_name?: string;
  public family_name?: string;
  public nickname?: string;
  public name?: string;
  public picture?: string;
  public locale?: string;
  public updated_at: string = "";
  public email: string = "";
  public email_verified?: boolean;
  public exception?: string | undefined;
  public iss: string = "";
  public sub: string = "";
  public aud: string = "";
  public iat: number = 0;
  public exp: number = 0;
  public at_hash: string = "";
  public nonce: string = "";
  public claims: Authorizer | undefined;
}

export default class LocalDataService {
  public static setAccessToken(accessToken: string) {
    localStorage.setItem(`${KEY_ACCESS_TOKEN}`, accessToken);
    return accessToken;
  }

  public static getAccessToken(): string | null {
    const saved = localStorage.getItem(`${KEY_ACCESS_TOKEN}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static setAuthorizer(authorizer: Authorizer) {
    localStorage.setItem(`${KEY_AUTHORIZER}`, JSON.stringify(authorizer));
    return authorizer;
  }

  public static getAuthorizer(): Authorizer {
    const saved = localStorage.getItem(`${KEY_AUTHORIZER}`);
    if (saved) {
      const authorizer = JSON.parse(saved) as Authorizer;
      return authorizer;
    } else {
      return new LocalAuthorizer();
    }
  }

  public static setUser(account: OperationMeResponse) {
    localStorage.setItem(`${KEY_USER}`, JSON.stringify(account));
    return account;
  }

  public static getUser(): OperationMeResponse | null {
    const saved = localStorage.getItem(`${KEY_USER}`);
    if (saved) {
      const user = JSON.parse(saved) as OperationMeResponse;
      return user;
    } else {
      return null;
    }
  }

  public static setCompany(company: OperationCompanyResponse) {
    localStorage.setItem(KEY_COMPANY, JSON.stringify(company));
    return company;
  }

  public static getCompany(): OperationCompanyResponse | null {
    const saved = localStorage.getItem(KEY_COMPANY);
    if (saved) {
      try {
        const company = JSON.parse(saved) as OperationCompanyResponse;
        return company;
      } catch {
        return null;
      }
    }
    return null;
  }

  public static setNurseryId(id: number) {
    localStorage.setItem(`${KEY_NURSERY_ID}`, String(id));
    return id;
  }

  public static getNurseryId(): number | null {
    const id = localStorage.getItem(`${KEY_NURSERY_ID}`);
    if (id) {
      try {
        const parsed = parseInt(id as string);
        return parsed;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }

  public static setCompanyId(id: number) {
    localStorage.setItem(`${KEY_COMPANY_ID}`, String(id));
    return id;
  }

  public static getCompanyId(): string | null {
    const id = localStorage.getItem(`${KEY_COMPANY_ID}`);
    if (id) {
      return id;
    } else {
      return null;
    }
  }
}
