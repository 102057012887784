
import LocalDataService from "@/service/LocalDataService";
import { userModule } from "@/store/dataModule/userModule";
import { OperationUserResponse } from "chaild-api/lib";
import Vue from "vue";
export default Vue.extend({
  async mounted() {
    await this.listUsers();
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    users: function (): OperationUserResponse[] {
      return userModule.users;
    },
  },

  methods: {
    async listUsers() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await userModule.listUsers({
          companyId: companyId,
        });
      }
      this.isLoading = false;
    },
    create() {
      this.$router.push({ name: "AdminUserEdit" });
    },
  },
});
