
import LocalDataService from "@/service/LocalDataService";
import { OperationCompanyResponse } from "chaild-api/lib";
import Vue from "vue";
import { navMenuItems } from "@/constants/navigation";
import { meModule } from "@/store/dataModule/meModule";

export default Vue.extend({
  data() {
    return {
      navMenuItems: navMenuItems,
    };
  },

  computed: {
    isShow: function (): boolean {
      if (this.$route.path == "/select-company") {
        return false;
      } else {
        return true;
      }
    },
    currentCompany: function (): OperationCompanyResponse | null {
      if (meModule.company) {
        return meModule.company;
      } else {
        return LocalDataService.getCompany();
      }
    },
  },

  methods: {
    logout() {
      const answer = confirm("ログアウトしますか？");
      if (answer) {
        this.$router.push({ path: "/logout" });
      }
    },
  },
});
