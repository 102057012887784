import ApiClient from "@/api/ApiClient";
import { OperationUserResponse } from "chaild-api/lib";

export interface PostUserRequest {
  companyId: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  dateOfBirth: string;
  gender: "male" | "female";
  address?: string;
  building?: string;
  phoneNumber?: string;
  isAdmin: boolean;
}

export interface PutUserRequst {
  userId: number;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  dateOfBirth: string;
  gender: "male" | "female";
  address?: string;
  building?: string;
  phoneNumber?: string;
  isAdmin: boolean;
}

export default class ApiUser {
  public static async listUsers(input: { companyId: string }): Promise<OperationUserResponse[]> {
    const response = await ApiClient.get(`/user`, input);
    return response.users;
  }

  public static async getUser(input: { userId: string }): Promise<OperationUserResponse> {
    const response = await ApiClient.get(`/user/${input.userId}`, null);
    return response;
  }

  public static async createUser(input: PostUserRequest): Promise<OperationUserResponse> {
    const response = await ApiClient.post(`/user`, input);
    return response;
  }

  public static async updateUser(input: PutUserRequst): Promise<OperationUserResponse> {
    const response = await ApiClient.put(`/user/${input.userId}`, input);
    return response;
  }

  public static async deleteUser(input: { userId: string }): Promise<OperationUserResponse> {
    const response = await ApiClient.delete(`/user/${input.userId}`, null);
    return response;
  }
}
