import ApiClient from "@/api/ApiClient";
import { OperationNurseryResponse } from "chaild-api/lib";

export interface PostNurseryRequest {
  companyId: string;
  nurseryName: string;
  departmentCode?: string;
  postalCode?: string;
  prefecture?: string;
  address?: string;
  building?: string;
  phoneNumber?: string;
  faxNumber?: string;
  representativeEmail?: string;
  email: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  category: "general" | "help";
  dateOfBirth: string; // YYYY-MM-DD
  gender: "male" | "female";
  joinDate: string;
  assignDate: string;
  // beneficiaryBank: string; // 金融機関名
  // branchName: string;
  // accountCategory: 'normal' | 'general' | 'saving' | 'current';
  // accountNumber: string;
  // accountHolder: string;
  // withdrawalDate: string;
}

export type PutNurseryRequest = {
  nurseryId: string;
  nurseryName: string;
  departmentCode?: string;
  postalCode?: string;
  prefecture?: string;
  address?: string;
  building?: string;
  phoneNumber?: string;
  faxNumber?: string;
  representativeEmail?: string;
  email: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  category: "general" | "help";
  dateOfBirth: string; // YYYY-MM-DD
  gender: "male" | "female";
  joinDate: string;
  assignDate: string;
  // bank account
  beneficiaryBank: string; // 金融機関名
  branchName: string;
  accountCategory: "normal" | "general" | "saving" | "current";
  accountNumber: string;
  accountHolder: string;
  withdrawalDate: string;
};

export type PutBankAccountRequest = {
  nurseryId: string;
  beneficiaryBank: string; // 金融機関名
  branchName: string;
  accountCategory: "normal" | "general" | "saving" | "current";
  accountNumber: string;
  accountHolder: string;
  withdrawalDate: string;
};

export default class ApiNursery {
  public static async listNurseries(input: { companyId: string }) {
    const response = await ApiClient.get("/nursery", input);
    return response;
  }

  public static async getNursery(input: { nurseryId: string }): Promise<OperationNurseryResponse> {
    const response = await ApiClient.get(`/nursery/${input.nurseryId}`, null);
    return response;
  }

  public static async updateNursery(input: PutNurseryRequest): Promise<OperationNurseryResponse> {
    const response = await ApiClient.put(`/nursery/${input.nurseryId}`, input);
    return response;
  }

  public static async createNursery(input: PostNurseryRequest): Promise<OperationNurseryResponse> {
    const response = await ApiClient.post(`/nursery`, input);
    return response;
  }

  static async updateBankAccount(input: PutBankAccountRequest) {
    return await ApiClient.put(`/nursery/${input.nurseryId}/bank-account`, input);
  }
}
