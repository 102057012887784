
import Vue from "vue";
import { PutCompanyRequest } from "@/api/ApiCompany";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";
import { companyModule } from "@/store/dataModule/companyModule";
import { userModule } from "@/store/dataModule/userModule";
import { OperationCompanyResponse } from "chaild-api/lib";

export default Vue.extend({
  mixins: [ValidationMixin],

  async mounted() {
    await this.getCompany();
    await this.listUsers();
  },

  data() {
    return {
      isLoading: false,
      isFormValid: false,
      inputValue: {} as PutCompanyRequest,
    };
  },

  computed: {
    userItems: function () {
      if (userModule.users) {
        const items = userModule.users.map((u) => {
          return {
            text: u.lastName + u.firstName,
            value: u.userId,
          };
        });
        return items;
      }
      return [];
    },
    company: function (): OperationCompanyResponse | null {
      return companyModule.company;
    },
  },

  methods: {
    async getCompany() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        const response = await companyModule.getCompany({
          companyId: companyId,
        });
        if (response) {
          this.inputValue = response as any;
          (this.inputValue as any).representativeUserId = response.representative?.userId as any;
        }
      }
      this.isLoading = false;
    },
    async listUsers() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await userModule.listUsers({
          companyId: companyId,
        });
      }
      this.isLoading = false;
    },
    async updateCompany() {
      this.isLoading = true;
      if (this.inputValue && this.inputValue.companyId) {
        await companyModule.updateCompany(this.inputValue);
        await this.getCompany();
      }
      this.isLoading = false;
    },
  },
});
