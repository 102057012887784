import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Logout from "@/views/auth/Logout.vue";

import AccountingSetting from "@/views/accounting/AccountingSetting.vue";
import AdminAccount from "@/views/admin/AdminAccount.vue";
import AdminUserDetail from "@/views/admin/AdminUserDetail.vue";
import AdminUserEdit from "@/views/admin/AdminUserEdit.vue";
import AdminUserSetting from "@/views/admin/AdminUserSetting.vue";
import InvoiceList from "@/views/invoice/InvoiceList.vue";
import SchoolInvoice from "@/views/invoice/SchoolInvoice.vue";
import SchoolDetailSetting from "@/views/school/SchoolDetailSetting.vue";
import SchoolSetting from "@/views/school/SchoolSetting.vue";
import MemoruSales from "@/views/memoru/MemoruSales.vue";
import MemoruSetting from "@/views/memoru/MemoruSetting.vue";
import GuidancePlanForm from "@/views/guidance/GuidancePlanForm.vue";
import GuidancePlanFormItem from "@/views/guidance/GuidancePlanFormItem.vue";

import SelectCompany from "@/views/auth/SelectCompany.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/accounting-setting",
    name: "AccountingSetting",
    component: AccountingSetting,
  },
  {
    path: "/admin-account",
    name: "AdminAccount",
    component: AdminAccount,
  },
  {
    path: "/admin-user/:userId",
    name: "AdminUserDetail",
    component: AdminUserDetail,
  },
  {
    path: "/admin-user/edit/:userId?",
    name: "AdminUserEdit",
    component: AdminUserEdit,
  },
  {
    path: "/admin-user",
    name: "AdminUserSetting",
    component: AdminUserSetting,
  },
  {
    path: "/invoice/:yearMonth?",
    name: "InvoiceList",
    component: InvoiceList,
  },
  {
    path: "/invoice/:nurseryId/:yearMonth",
    name: "SchoolInvoice",
    component: SchoolInvoice,
  },
  {
    path: "/school/:id",
    name: "SchoolDetailSetting",
    component: SchoolDetailSetting,
  },
  {
    path: "/school",
    name: "SchoolSetting",
    component: SchoolSetting,
  },
  {
    path: "/select-company",
    name: "SelectCompany",
    component: SelectCompany,
  },
  {
    path: "/memoru/sales",
    name: "MemoruSales",
    component: MemoruSales,
  },
  {
    path: "/memoru/setting",
    name: "MemoruSetting",
    component: MemoruSetting,
  },
  {
    path: "/guidance/form",
    name: "GuidancePlanForm",
    component: GuidancePlanForm,
  },
  {
    path: "/guidance/form-item",
    name: "GuidancePlanFormItem",
    component: GuidancePlanFormItem,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

export default router;
