import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import { OperationNurseryResponse } from "chaild-api/lib";
import ApiNursery, { PostNurseryRequest, PutNurseryRequest } from "@/api/ApiNursery";

export interface NurseryState {
  nurseries: OperationNurseryResponse[];
  nursery: OperationNurseryResponse | null;
}

@Module({ dynamic: true, store, name: "nursery", namespaced: true })
class NurseryModule extends VuexModule implements NurseryState {
  public nurseries: OperationNurseryResponse[] = [];
  public nursery: OperationNurseryResponse | null = null;

  @Action({ rawError: true })
  public async listNurseries(input: { companyId: string }) {
    const response = await ApiNursery.listNurseries(input);
    if (response) {
      this.setNurseries(response.nurseries);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getNursery(input: { nurseryId: string }) {
    const response = await ApiNursery.getNursery(input);
    if (response) {
      this.setNursery(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async createNursery(input: PostNurseryRequest) {
    const response = await ApiNursery.createNursery(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateNursery(input: PutNurseryRequest) {
    const response = await ApiNursery.updateNursery(input);
    if (response) {
      return response;
    }
  }

  @Mutation
  public setNurseries(value: OperationNurseryResponse[]) {
    this.nurseries = value;
  }

  @Mutation
  public setNursery(value: OperationNurseryResponse) {
    this.nursery = value;
  }
}

export const nurseryModule = getModule(NurseryModule);
