import ApiClient from "./ApiClient";

export type ListSalesRequest = {
  monthFrom: string;
  monthTo: string;
  nurseryId: string | number;
};

export type UpdateSettingRequest = {
  companyId: string;
  photoPrice: number | string;
  photoLargePrice: number | string;
  photo2LargePrice: number | string;
  photoKgPrice: number | string;
  movie30price: number | string;
  movie60price: number | string;
  movie90price: number | string;
  movie120price: number | string;
  movie150price: number | string;
};

export default class ApiMemoru {
  /**
   * sales
   */
  static async listSales(input: ListSalesRequest) {
    return await ApiClient.get("/memoru/sales", input);
  }

  /**
   * setting
   */
  static async listSetting(input: { companyId: string }) {
    return await ApiClient.get("/memoru/setting", input);
  }

  static async updateSetting(input: UpdateSettingRequest) {
    return await ApiClient.put("/memoru/setting", input);
  }
}
