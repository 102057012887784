
import Vue from "vue";
import NavigationDrawer from "@/components/organisms/common/NavigationDrawer.vue";
import { auth0Module } from "@/store/dataModule/auth0Module";
import { meModule } from "@/store/dataModule/meModule";
import LocalDataService from "@/service/LocalDataService";

export default Vue.extend({
  name: "App",

  components: {
    NavigationDrawer,
  },

  data() {
    return {
      fromAuth0: false,
    };
  },

  async created() {
    await auth0Module.initClient();

    // auth0からの遷移の場合
    if (this.$route.query && this.$route.query.code && this.$route.query.state) {
      await auth0Module.handleAuth0().catch((error) => console.warn(error));
      this.fromAuth0 = true;
    }

    // check token
    if (this.tokenExists) {
      const response = await meModule.fetchMe().catch();
      if (response && response.companies) {
        if (response.companies.length > 0) {
          const company = LocalDataService.getCompany();
          if (!company) {
            this.$router.push("/select-company");
          } else {
            if (this.$route.path == "/signin") {
              this.goTop();
            }
          }
        } else {
          alert("ログインする企業が存在しません");
        }
      } else {
        const response = await meModule.postWithNoBody();
        if (response) {
          this.$router.push("/select-company");
        }
      }
    } else {
      auth0Module.signIn();
    }
  },

  computed: {
    tokenExists(): boolean {
      const token = auth0Module.accessToken;
      return token ? true : false;
    },
  },

  methods: {
    goTop() {
      this.$router.push({ path: "/invoice" });
    },
    signUp() {
      this.$router.push({ path: "/signup" });
    },
  },
});
