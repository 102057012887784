import ApiClient from "@/api/ApiClient";
import {
  OperationCreateGuidancePlanFormItemMastRequestBody,
  OperationCreateGuidancePlanFormMastRequestBody,
  OperationGuidancePlanFormItemMastListResponse,
  OperationGuidancePlanFormItemMastResponse,
  OperationGuidancePlanFormMastListResponse,
  OperationGuidancePlanFormMastResponse,
  OperationUpdateGuidancePlanFormItemMastRequestBody,
  OperationUpdateGuidancePlanFormMastRequestBody,
} from "chaild-api/lib";
export default class ApiGuidance {
  /**
   * Guidance Plan Form Mast
   */
  static async listPlanFormMast(input: {
    companyId: string;
    limit?: number;
    skip?: number;
    type?: "general" | "yearly" | "monthly" | "weekly";
  }): Promise<OperationGuidancePlanFormMastListResponse> {
    return await ApiClient.get("/guidance-plan-form-mast", input);
  }

  static async createPlanFormMast(input: {
    companyId: string;
    body: OperationCreateGuidancePlanFormMastRequestBody;
  }): Promise<OperationGuidancePlanFormMastResponse> {
    return await ApiClient.post(
      `/guidance-plan-form-mast?companyId=${input.companyId}`,
      input.body
    );
  }

  static async updatePlanFormMast(input: {
    companyId: string;
    formMastId: string | number;
    body: OperationUpdateGuidancePlanFormMastRequestBody;
  }): Promise<OperationGuidancePlanFormMastResponse> {
    return await ApiClient.put(
      `/guidance-plan-form-mast/${input.formMastId}?companyId=${input.companyId}`,
      input.body
    );
  }

  static async deletePlanFormMast(input: {
    companyId: string;
    formMastId: string | number;
  }): Promise<any> {
    return await ApiClient.delete(`/guidance-plan-form-mast/${input.formMastId}`, input);
  }

  /**
   * Guidance Plan Form Item Mast
   */
  static async listPlanFormItemMast(input: {
    companyId: string;
    limit?: number;
    skip?: number;
  }): Promise<OperationGuidancePlanFormItemMastListResponse> {
    return await ApiClient.get("/guidance-plan-form-item-mast", input);
  }

  static async createPlanFormItemMast(input: {
    companyId: string;
    body: OperationCreateGuidancePlanFormItemMastRequestBody;
  }): Promise<OperationGuidancePlanFormItemMastResponse> {
    return await ApiClient.post(
      `/guidance-plan-form-item-mast?companyId=${input.companyId}`,
      input.body
    );
  }

  static async updatePlanFormItemMast(input: {
    companyId: string;
    itemMastId: string | number;
    body: OperationUpdateGuidancePlanFormItemMastRequestBody;
  }): Promise<OperationGuidancePlanFormItemMastResponse> {
    return await ApiClient.put(
      `/guidance-plan-form-item-mast/${input.itemMastId}?companyId=${input.companyId}`,
      input.body
    );
  }

  static async deletePlanFormItemMast(input: {
    companyId: string;
    itemMastId: string | number;
  }): Promise<any> {
    return await ApiClient.delete(
      `/guidance-plan-form-item-mast/${input.itemMastId}?companyId=${input.companyId}`,
      null
    );
  }
}
