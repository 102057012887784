import Vue from "vue";

export default Vue.extend({
  name: "validation-mixin",

  methods: {
    required(text: string): string | boolean {
      return !!text || "必須";
    },
    isKatakana(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.match(/^[\u30a1-\u30fc]+$/) !== null;
    },
    isTel(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.match(/0\d{1,4}-\d{1,4}-\d{4}$/) !== null;
    },
    isAccountNumber(text: string): boolean {
      if (!text) {
        return true;
      }
      return text.length == 7 || text.length == 6;
    },
    isWithdrawalDate(text: number): boolean {
      if (!text) {
        return true;
      }
      return text > 0 && text < 32;
    },
  },
});
