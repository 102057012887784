/* eslint-disable */
import axios, { AxiosError } from "axios";

import Swal from "sweetalert2";
import querystring from "query-string";
import LocalDataService from "@/service/LocalDataService";
import ErrorMessageUtil from "@/utils/ErrorMessageUtil";

const baseUrl = process.env.VUE_APP_API_URL;
const xkey = process.env.VUE_APP_X_API_KEY;
const client = axios.create();

interface Response {
  status: number;
  config: {
    url: string;
  };
  data: {
    [key: string]: string;
  };
}

interface ErrorResponse {
  status: number;
  config: {
    url: string;
  };
  data: {
    data: {
      message: string;
    };
    status: string;
  };
}

const getHeaders = async () => {
  // const token = await auth0Module.getAuth0Token();
  const token = LocalDataService.getAccessToken();
  return {
    Authorization: `${token}`,
    "Content-Type": "application/json; charset=utf-8",
    "x-api-key": xkey,
  };
};

export enum ErrorType {
  UNAUTHORIZED = "unauthorized",
  FIRSTLOGIN = "firstLogin",
}

client.interceptors.response.use(
  async (response) => {
    // (response) => {
    try {
      const r: Response = response as Response;
      // return response
      if (r.config.url) {
        if (r.status !== 200) {
          throw Promise.reject(response);
        }
        if (r.data.error) {
          if (r.data.error != "E003") {
            const errorMessage = ErrorMessageUtil.getErrorMessage(r.data.error);
            console.warn("200 Error!");
            alert(`${r.data.error + "\n" + errorMessage}`);
            throw Promise.reject(response);
          }
        }
        return response;
      } else {
        if (r.data.status !== "0") {
          // status != 0の時は、何かしらのエラー
          throw Promise.reject(response);
        }
        return response;
      }
    } catch (error) {
      throw error;
    }
  },
  async (error) => {
    try {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status >= 500) {
          // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          const message = String(axiosError.response.data.message);
          Swal.fire(status, message, "error");
          throw Promise.reject(error);
        }
        if (axiosError.response.status === 401) {
          if (process.env) {
            window.location.href = "/logout";
            throw Promise.reject(ErrorType.UNAUTHORIZED);
          }
        }
        if (axiosError.response.status === 400) {
          // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          const message = String(axiosError.response.data.message);
          Swal.fire(status, message, "error");
          throw Promise.reject(error);
        }
      }
    } catch (error) {
      throw Promise.reject(error);
    }
  }
);

// REST
export default class ApiClient {
  public static async get(endpoint: string, params: { [key: string]: any } | null) {
    if (params) {
      try {
        const response = await client.get(
          `${baseUrl}${endpoint}?${querystring.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
          })}`,
          {
            headers: await getHeaders(),
            data: {},
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await client.get(`${baseUrl}${endpoint}`, {
          headers: await getHeaders(),
          data: {},
        });
        return response.data;
      } catch (error: any) {
        if (error.data && error.data.error) {
          throw error.data;
        } else {
          throw error;
        }
      }
    }
  }

  public static async post(endpoint: string, params: { [key: string]: any } | null, isAuth = true) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        "Content-Type": "application/json",
        "X-API-KEY": xApiKey ? xApiKey : "",
        Authorization: `${token}`,
      };
    } else {
      headers = { "Content-Type": "application/json" };
    }
    return client
      .post(`${baseUrl}${endpoint}`, JSON.stringify(params), { headers })
      .then((response) => response.data)
      .catch((e) => {
        console.warn(e);
        throw e;
        // return
      });
  }

  public static async put(endpoint: string, params: { [key: string]: any } | null) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      "Content-Type": "application/json",
      "X-API-KEY": xApiKey ? xApiKey : "",
      Authorization: `${token}`,
    };
    if (params) {
      return client
        .put(`${baseUrl}${endpoint}`, JSON.stringify(params), { headers })
        .then((response) => response.data)
        .catch((e) => {
          console.warn(e);
          throw e;
        });
    } else {
      return client
        .put(`${baseUrl}${endpoint}`, { headers })
        .then((response) => response.data)
        .catch((e) => {
          console.warn(e);
          throw e;
        });
    }
  }

  public static async delete(endpoint: string, params: { [key: string]: any } | null) {
    // const token = await auth0Module.getAuth0Token();
    const token = LocalDataService.getAccessToken();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      "Content-Type": "application/json",
      "X-API-KEY": xApiKey ? xApiKey : "",
      Authorization: `${token}`,
    };
    if (params) {
      return client
        .delete(`${baseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`, {
          headers,
          data: {},
        })
        .then((response) => response.data);
    } else {
      return client
        .delete(`${baseUrl}${endpoint}`, {
          headers,
          data: {},
        })
        .then((response) => response.data);
    }
  }

  // public static async deleteWithBody(
  //   endpoint: string,
  //   params: { [key: string]: any } | null,
  // ) {
  //   // const token = await auth0Module.getAuth0Token();
  //   const token = LocalDataService.getAccessToken();
  //   const xApiKey = process.env.VUE_APP_X_API_KEY;
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'X-API-KEY': xApiKey ? xApiKey : '',
  //     'Authorization': `${token}`,
  //   };
  //   if (params) {
  //     return client.delete(
  //       `${baseUrl}${endpoint}`, {
  //       headers,
  //       data: {
  //         params
  //       },
  //     }
  //     ).then((response) => response.data);
  //   } else {
  //     return client.delete(
  //       `${baseUrl}${endpoint}`, {
  //       headers,
  //       data: {},
  //     },
  //     ).then((response) => response.data);
  //   }
  // }
}
