export const invoiceStatusItems = [
  { value: "applied", label: "施設入力完了" },
  { value: "salesSettled", label: "売上確定" },
  { value: "invoiceSettled", label: "請求確定" },
];

export const paymentMethodItems = [
  { text: "集金", value: null },
  { text: "口座振替", value: "withdrawal" },
  { text: "振り込み", value: "invoice" },
  { text: "クレジットカード", value: "card" },
];
