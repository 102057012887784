export default class ErrorMessageUtil {
  public static errors = [
    { code: "E003", message: "ユーザーが存在しません" },
    // { code: 'E004', message: 'ユーザーが既に存在しています。', },
    { code: "E005", message: "指定されたObjectTypeが不正です。" },
    { code: "E006", message: "朝食の記録が存在しません。" },
    { code: "E007", message: "朝食の記録が既に存在します。" },
    { code: "E008", message: "夕食の記録が存在しません。" },
    //
    { code: "E120", message: "延長保育パターンが見つかりません。" },
    { code: "E121", message: "時間枠に被りがあるため保存できません。" },
    { code: "E122", message: "時間枠が見つかりません。" },
    { code: "E123", message: "打刻設定が存在しません。" },
    { code: "E124", message: "指定した延長保育日誌は指定した保育園のものではありません。" },
    { code: "E125", message: "指定した園提供サービスはマスターに登録されていません。" },
    { code: "E126", message: "指定した園提供サービスは既に登録されています。" },
    { code: "E127", message: "指定した園提供サービスは利用登録されていません。" },
    {
      code: "E128",
      message: "月額制の施設提供サービスの場合は、1日 日付を利用日として設定してください。",
    },
    { code: "E129", message: "指定した施設提供サービスは利用されているため削除できません。" },
    //
    { code: "E130", message: "施設提供サービスのserviceTypeの形式が不正です。" },
    { code: "E131", message: "施設提供サービスのpaymentMonthTypeの形式が不正です。" },
    { code: "E132", message: "施設提供サービスのaccountTypeの形式が不正です。" },
    { code: "E133", message: "ユーザーは指定した運営企業に所属していません。" },
    { code: "E134", message: "運営会社が見つかりません。" },
    { code: "E135", message: "指定された請求書が存在しません。" },
    { code: "E136", message: "請求書のステータスが不正です。" },
    { code: "E137", message: "請求書がまだ確定されていません。" },
    {
      code: "E138",
      message: "ファイルの読み込みに失敗しました。CSVファイルの形式を確認してください。",
    },
    { code: "E139", message: "CSVファイルの園児コードが不正です。" },
    //
    { code: "E140", message: "CSVファイルの売掛金の値が不正です。" },
    { code: "E141", message: "売上情報のエクスポート状況が存在しません。" },
    { code: "E142", message: "売掛金残高のインポート状況が存在しません。" },
    {
      code: "E143",
      message: "この月の売上情報が送信されているため, この登園・降園記録は編集できません。",
    },
    { code: "E144", message: "確定ステータスの小口現金出納帳は変更できません。" },
    {
      code: "E145",
      message:
        "この月の売上情報が確定しているため, 対象期間を含む保育料・認定情報は編集できません。",
    },
    { code: "E146", message: "指定されたその他サービス詳細が存在しません。" },
    {
      code: "E147",
      message: "保育料・認定情報が登録されていない園児がいます。設定してから送信してください。",
    },
    { code: "E148", message: "運営企業のサービス設定が存在しません。" },
    {
      code: "E149",
      message: "権限不足のため小口現金出納帳の確定ステータスを解除することができません。",
    },
    //
    { code: "E150", message: "下書きステータスではないため操作することができません。" },
    {
      code: "E151",
      message: "この月の売上情報が確定しているため, 対象期間を含む園提供サービスは編集できません。",
    },
    { code: "E152", message: "園の振込口座の設定が見つかりません。" },
    {
      code: "E153",
      message: "指定の園児は卒園もしくは退園しているため登録・編集をすることができません。",
    },
  ];

  public static getErrorMessage(code: string): string {
    if (!code) {
      return "";
    }
    const error = this.errors.find((e) => e.code == code);
    return error ? error.message : "";
  }
}
