import LocalDataService from "@/service/LocalDataService";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import { MemoruPurchaseHistoryListResponse } from "chaild-api/lib";
import ApiMemoru, { ListSalesRequest, UpdateSettingRequest } from "@/api/ApiMemoru";

export interface MemoruState {
  sales: MemoruPurchaseHistoryListResponse | null;
  setting: UpdateSettingRequest | null;
}

@Module({ dynamic: true, store, name: "memoru", namespaced: true })
class MemoruModule extends VuexModule implements MemoruState {
  sales: MemoruPurchaseHistoryListResponse | null = null;
  setting: UpdateSettingRequest | null = null;

  @Action({ rawError: true })
  public async listSales(input: ListSalesRequest) {
    const response = await ApiMemoru.listSales(input).catch();
    if (response) {
      this.setSales(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async listSetting(input: { companyId: string }) {
    const response = await ApiMemoru.listSetting(input).catch();
    if (response) {
      this.setSetting(response);
    }
    return response;
  }

  @Action({ rawError: true })
  public async updateSetting() {
    if (this.setting) {
      return await ApiMemoru.updateSetting(this.setting);
    }
  }

  @Mutation
  public setSales(value: MemoruPurchaseHistoryListResponse) {
    this.sales = value;
  }

  @Mutation
  public setSetting(value: UpdateSettingRequest) {
    this.setting = value;
  }
}

export const memoruModule = getModule(MemoruModule);
