import ApiClient from "@/api/ApiClient";
import {
  ExportInvoiceResponse,
  ExportInvoiceStatusResponse,
  ImportAccountsReceivableResponse,
  ImportReceivableStatusResponse,
  InvoiceResponse,
  OperationChildInvoiceResponse,
  OperationDownloadInvoiceResponse,
  OperationInvoiceDetailResponse,
  OperationInvoiceResponse,
} from "chaild-api/lib/index";

export default class ApiInvoice {
  public static async listInvoices({
    companyId,
    year,
    month,
  }: {
    companyId: string | number;
    year: string | number;
    month: string | number;
  }): Promise<OperationInvoiceResponse[]> {
    const response = await ApiClient.get(`/invoice`, {
      companyId,
      year,
      month,
    });
    return response;
  }

  public static async listSchoolInvoices({
    nurseryId,
    year,
    month,
  }: {
    nurseryId: string | number;
    year: string | number;
    month: string | number;
  }): Promise<OperationInvoiceDetailResponse> {
    const response = await ApiClient.get(`/invoice/nursery/${nurseryId}`, {
      year,
      month,
    });
    return response as OperationInvoiceDetailResponse;
  }

  public static async updateChildRecord({
    childId,
    year,
    month,
    monthlyAdditionalFeeDiff,
    tempNurseryFeeDiff,
    monthlyNurseryFeeDiff,
    spotAdditionalFeeDiff,
    foodServiceFeeDiff,
    extraServiceDiffs,
  }: {
    childId: number;
    year: string;
    month: string;
    monthlyNurseryFeeDiff: number;
    tempNurseryFeeDiff: number;
    monthlyAdditionalFeeDiff: number;
    spotAdditionalFeeDiff: number;
    foodServiceFeeDiff: number;
    extraServiceDiffs: Array<{
      extraServiceBillingDetailId: number;
      adjustmentAmount: number;
    }>;
  }) {
    const response = await ApiClient.put(`/invoice`, {
      childId,
      year,
      month,
      monthlyAdditionalFeeDiff,
      tempNurseryFeeDiff,
      monthlyNurseryFeeDiff,
      spotAdditionalFeeDiff,
      foodServiceFeeDiff,
      extraServiceDiffs,
    });

    return response as OperationChildInvoiceResponse;
  }

  static async updateStatus(input: {
    nurseryIds: number[];
    status: string;
    year: string | number;
    month: string | number;
  }) {
    return await ApiClient.put("/invoice/status", input);
  }

  public static async importRecievables({
    companyId,
    year,
    month,
    filePath,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
    filePath: string;
  }) {
    const response = await ApiClient.post("/invoice/import", {
      companyId,
      year,
      month,
      filePath,
    });
    return response as ImportAccountsReceivableResponse;
  }

  public static async getUploadStatus({ importStatusId }: { importStatusId: number | string }) {
    const response = await ApiClient.get(`/invoice/import/status/${importStatusId}`, null);
    return response as ImportReceivableStatusResponse;
  }

  public static async exportSales({
    companyId,
    year,
    month,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
  }) {
    const response = await ApiClient.post("/invoice/export", {
      companyId,
      year,
      month,
    });
    return response as ExportInvoiceResponse;
  }

  public static async getExportStatus({ exportStatusId }: { exportStatusId: number | string }) {
    const response = await ApiClient.get(`/invoice/export/status/${exportStatusId}`, null);
    return response as ExportInvoiceStatusResponse;
  }

  public static async donwloadInvoice({
    childId,
    year,
    month,
  }: {
    childId: number;
    year: number;
    month: number;
  }) {
    const response = await ApiClient.get(`/invoice/download`, {
      childId,
      year,
      month,
    }).catch();
    return response as OperationDownloadInvoiceResponse;
  }
}
