
import Vue from "vue";

import { meModule } from "@/store/dataModule/meModule";
import { OperationCompanyResponse } from "chaild-api/lib";
import LocalDataService from "@/service/LocalDataService";

export default Vue.extend({
  computed: {
    me() {
      return meModule.me;
    },
    companies(): OperationCompanyResponse[] {
      if (this.me) {
        return this.me.companies;
      }
      return [];
    },
  },
  methods: {
    selectCompany(company: OperationCompanyResponse) {
      LocalDataService.setCompany(company);
      LocalDataService.setCompanyId(company.companyId);
      meModule.setCompany(company);
      this.$router.push({ path: "/invoice" });
      // this.$router.go(-1);
    },
  },
});
