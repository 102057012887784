import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import {
  OperationGuidancePlanFormItemMastListResponse,
  OperationGuidancePlanFormMastListResponse,
} from "chaild-api/lib";
import ApiGuidance from "@/api/ApiGuidance";

interface GuidanceState {
  // 帳票マスタ
  mastList: OperationGuidancePlanFormMastListResponse | null;
  // 帳票項目マスタ
  itemMastList: OperationGuidancePlanFormItemMastListResponse | null;
}

@Module({ dynamic: true, store, name: "guidance", namespaced: true })
class GuidanceModule extends VuexModule implements GuidanceState {
  mastList: GuidanceState["mastList"] = null;
  itemMastList: GuidanceState["itemMastList"] = null;

  @Action({ rawError: true })
  async listMast(input: {
    companyId: string;
    limit?: number;
    skip?: number;
    type?: "general" | "yearly" | "monthly" | "weekly";
  }) {
    const res = await ApiGuidance.listPlanFormMast(input);
    if (res) {
      this.setMastList(res);
    }
    return res;
  }

  @Action({ rawError: true })
  async listItemMast(input: { companyId: string; limit?: number; skip?: number }) {
    const res = await ApiGuidance.listPlanFormItemMast(input);
    if (res) {
      this.setItemMastList(res);
    }
    return res;
  }

  @Mutation
  setMastList(value: GuidanceState["mastList"]) {
    this.mastList = value;
  }

  @Mutation
  setItemMastList(value: GuidanceState["itemMastList"]) {
    this.itemMastList = value;
  }
}

export const guidanceModule = getModule(GuidanceModule);
