import ApiClient from "@/api/ApiClient";
import axios from "axios";

interface OperationResourceResponse {
  url: string;
  filePath: string;
}

export default class ApiResource {
  public static async getUploadInfo({
    category,
    filename,
    companyId,
  }: {
    category: string;
    filename: string;
    companyId: number;
  }) {
    const response = await ApiClient.post("/resource", {
      category,
      filename,
      companyId,
    });
    return response as OperationResourceResponse;
  }

  public static async uploadFile(input: { url: string; file: File }) {
    axios.defaults.headers.common["Content-Type"] = input.file.type;

    const response = await axios({
      method: "PUT",
      url: input.url,
      headers: {
        "Content-Type": input.file.type,
      },
      data: input.file,
    });

    if (response) {
      return response;
    }
    return null;
  }
}
