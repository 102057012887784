
import { UpdateSettingRequest } from "@/api/ApiMemoru";
import LocalDataService from "@/service/LocalDataService";
import { memoruModule } from "@/store/dataModule/memoruModule";
import Vue from "vue";

export default Vue.extend({
  name: "MemoruSetting",

  async mounted() {
    await this.listSetting();
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    setting: {
      get(): UpdateSettingRequest | null {
        return memoruModule.setting;
      },
      set(value: UpdateSettingRequest) {
        memoruModule.setSetting(value);
      },
    },
  },

  methods: {
    async listSetting() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await memoruModule
          .listSetting({
            companyId,
          })
          .catch();
      }
      this.loading = false;
    },
    async updateSetting() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId && this.setting) {
        this.setting.companyId = companyId;
        const response = await memoruModule.updateSetting().catch(() => (this.loading = false));
        if (response) {
          alert("更新しました");
        }
      }
      this.loading = false;
    },
  },
});
