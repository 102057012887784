import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import { OperationUserResponse } from "chaild-api/lib";
import ApiUser, { PostUserRequest, PutUserRequst } from "@/api/ApiUser";

export interface UserState {
  users: OperationUserResponse[];
  user: OperationUserResponse | null;
}

@Module({ dynamic: true, store, name: "user", namespaced: true })
class UserModule extends VuexModule implements UserState {
  public users: OperationUserResponse[] = [];
  public user: OperationUserResponse | null = null;

  @Action({ rawError: true })
  public async listUsers(input: { companyId: string }) {
    const response = await ApiUser.listUsers(input);
    if (response) {
      this.setUsers(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async getUser(input: { userId: string }) {
    const response = await ApiUser.getUser(input);
    if (response) {
      this.setUser(response);
      return response;
    }
  }

  @Action({ rawError: true })
  public async createUser(input: PostUserRequest) {
    const response = await ApiUser.createUser(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async updateUser(input: PutUserRequst) {
    const response = await ApiUser.updateUser(input);
    if (response) {
      return response;
    }
  }

  @Action({ rawError: true })
  public async deleteUser(input: { userId: string }) {
    const response = await ApiUser.deleteUser(input);
    if (response) {
      return response;
    }
  }

  @Mutation
  public setUsers(value: OperationUserResponse[]) {
    this.users = value;
  }

  @Mutation
  public setUser(value: OperationUserResponse) {
    this.user = value;
  }
}

export const userModule = getModule(UserModule);
