
import Vue from "vue";
import ApiNursery, { PutNurseryRequest } from "@/api/ApiNursery";
import { nurseryModule } from "@/store/dataModule/nurseryModule";
import ValidationMixin from "@/mixins/ValidationMixin";
import LocalDataService from "@/service/LocalDataService";

export default Vue.extend({
  mixins: [ValidationMixin],

  async mounted() {
    if (this.nurseryId) {
      await this.getNursery();
    }
  },

  data() {
    return {
      isLoading: false,
      isFormValid: false,
      isBankAccountFormValid: false,
      inputValue: {} as PutNurseryRequest,
      accountCategoryItems: [
        { text: "普通", value: "normal" },
        { text: "総合", value: "general" },
        { text: "貯蓄", value: "saving" },
        { text: "当座", value: "current" },
      ],
      genderItems: [
        { text: "男性", value: "male" },
        { text: "女性", value: "female" },
      ],
      categoryItems: [
        { text: "一般", value: "general" },
        { text: "ヘルプ", value: "help" },
      ],
    };
  },

  computed: {
    nurseryId: function () {
      return this.$route.params.id;
    },
  },

  methods: {
    async getNursery() {
      this.isLoading = true;
      if (this.nurseryId) {
        const response = await nurseryModule.getNursery({
          nurseryId: this.nurseryId,
        });
        if (response) {
          this.inputValue = response as any;
        }
      }
      this.isLoading = false;
    },
    async updateNursery() {
      this.isLoading = true;
      if (this.inputValue) {
        this.inputValue.category = "general";

        await nurseryModule
          .updateNursery({
            ...this.inputValue,
          })
          .catch((e) => console.warn(e));
        this.$router.push({ path: "/school" });
      }
      this.isLoading = false;
    },
    async createNursery() {
      this.isLoading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId && this.inputValue) {
        this.inputValue.category = "general";

        await nurseryModule
          .createNursery({
            companyId,
            ...this.inputValue,
          })
          .catch(() => (this.isLoading = false));
        this.$router.push({ path: "/school" });
      }
      this.isLoading = false;
    },
    async updateBankAccount() {
      this.isLoading = true;
      if (this.inputValue.nurseryId) {
        await ApiNursery.updateBankAccount({
          ...this.inputValue,
        }).catch(() => (this.isLoading = false));
        this.$router.push({ path: "/school" });
      }
      this.isLoading = false;
    },
  },
});
