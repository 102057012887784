
import Vue from "vue";
import { GuidancePlanFormItemCategoryItems } from "@/constants/guidance";
import {
  GuidancePlanItemCategoryType,
  NurseryGuidancePlanFormItemResponse,
  OperationGuidancePlanFormItemMastListResponse,
} from "chaild-api/lib";
import dayjs from "dayjs";
import { guidanceModule } from "@/store/dataModule/guidanceModule";
import LocalDataService from "@/service/LocalDataService";
import ApiGuidance from "@/api/ApiGuidance";

export default Vue.extend({
  name: "GuidancePlanFormItem",

  async mounted() {
    await this.listFormItem();
  },

  data() {
    return {
      loading: false,
      categoryItems: GuidancePlanFormItemCategoryItems,
      headers: [
        { text: "項目名", value: "name" },
        { text: "種類", value: "type" },
        { text: "作成日", value: "createdAt" },
        { text: "編集", value: "edit" },
        { text: "削除", value: "delete" },
      ],
      searchFilters: {
        limit: 3000,
        skip: 0,
      },
      input: {
        name: "",
        type: "" as GuidancePlanItemCategoryType,
        classIdList: [] as number[],
        itemMastId: 0 as number,
      },
      headerInput: {
        cols: "",
        rows: "",
      },
      editDialog: false,
    };
  },

  computed: {
    isUpdate: function (): boolean {
      return !!this.input.itemMastId;
    },
    itemResponse: function (): OperationGuidancePlanFormItemMastListResponse | null {
      return guidanceModule.itemMastList;
    },
  },

  methods: {
    async listFormItem() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        await guidanceModule.listItemMast({
          companyId,
          ...this.searchFilters,
        });
      }
      this.loading = false;
    },
    async createFormItem() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        const response = await ApiGuidance.createPlanFormItemMast({
          companyId,
          body: {
            ...this.input,
            option:
              this.input.type == "grid"
                ? {
                    header: {
                      cols: this.getHeaderArray(this.headerInput.cols),
                      rows: this.getHeaderArray(this.headerInput.rows),
                    },
                  }
                : undefined,
          },
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listFormItem();
        }
      }
      this.loading = false;
    },
    async updateFormItem() {
      this.loading = true;
      const companyId = LocalDataService.getCompanyId();
      if (companyId) {
        const response = await ApiGuidance.updatePlanFormItemMast({
          companyId,
          itemMastId: this.input.itemMastId,
          body: {
            ...this.input,
            option:
              this.input.type == "grid"
                ? {
                    header: {
                      cols: this.getHeaderArray(this.headerInput.cols),
                      rows: this.getHeaderArray(this.headerInput.rows),
                    },
                  }
                : undefined,
          },
        }).catch(() => (this.loading = false));
        if (response) {
          this.editDialog = false;
          this.clearInput();
          await this.listFormItem();
        }
      }
      this.loading = false;
    },
    async deleteFormItem(itemMastId: string) {
      const answer = confirm("削除しますか？");
      if (answer) {
        this.loading = true;
        const companyId = LocalDataService.getCompanyId();
        if (companyId && itemMastId) {
          const response = await ApiGuidance.deletePlanFormItemMast({
            companyId,
            itemMastId,
          }).catch(() => (this.loading = false));
          if (response) {
            alert("削除しました");
            await this.listFormItem();
          }
        }
        this.loading = false;
      }
    },
    openEditDialog(value?: NurseryGuidancePlanFormItemResponse) {
      if (value) {
        this.setInput(value);
      } else {
        this.clearInput();
      }
      this.editDialog = true;
    },
    getCategryText(value: string): string {
      const item = this.categoryItems.find((c) => c.value == value);
      return item?.text || "";
    },
    clearInput() {
      this.input = {
        name: "",
        type: "text",
        classIdList: [],
        itemMastId: 0,
      };
      this.headerInput = {
        cols: "",
        rows: "",
      };
    },
    setInput(value: NurseryGuidancePlanFormItemResponse) {
      this.input = {
        name: value.name,
        type: value.type,
        classIdList: value.classList?.map((c) => c.classId) || [],
        itemMastId: value.id,
      };
      if (value.type == "grid" && value.option) {
        this.headerInput = {
          cols: value.option.header.cols.join(","),
          rows: value.option.header.rows.join(","),
        };
      }
    },
    getHeaderArray(value: string): string[] {
      if (value) {
        return value.split(",");
      } else {
        return [];
      }
    },
    formatDate(value: string): string {
      if (!value) {
        return "";
      }
      return dayjs(value).format("YYYY/MM/DD");
    },
  },
});
