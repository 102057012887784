import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";
import ApiInvoice from "@/api/ApiInvoice";

import {
  InvoiceResponse,
  OperationChildInvoiceResponse,
  OperationInvoiceDetailResponse,
} from "chaild-api/lib/index";

export interface SchoolInvoiceState {
  invoiceDetail: OperationInvoiceDetailResponse | null;
  uploadId: number | null;
  importStatus: string | null;
  donwloadId: number | null;
  exportStatus: string | null;
}

@Module({ dynamic: true, store, name: "school-invoice", namespaced: true })
class SchoolInvoiceModule extends VuexModule implements SchoolInvoiceState {
  public invoiceDetail: OperationInvoiceDetailResponse | null = null;
  public uploadId: number | null = null;
  public importStatus: string | null = null;
  public donwloadId: number | null = null;
  public exportStatus: string | null = null;

  public allInvoices: InvoiceResponse[] = [];

  @Action({ rawError: true })
  public async getSchoolInvoice({
    nurseryId,
    year,
    month,
  }: {
    nurseryId: number;
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiInvoice.listSchoolInvoices({
        nurseryId,
        year,
        month,
      });
      this.setInvoiceDetail(response);
    } catch {
      return;
    }
  }

  @Mutation
  public setInvoiceDetail(detail: OperationInvoiceDetailResponse) {
    this.invoiceDetail = detail;
  }

  @Mutation
  public refreshChildInvoice(childInvoice: OperationChildInvoiceResponse) {
    if (this.invoiceDetail) {
      const invoices = this.invoiceDetail.invoices.map((invoice) => {
        if (invoice.child.childId === childInvoice.child.childId) {
          return childInvoice;
        }
        return invoice;
      });
      const newDetail = { ...this.invoiceDetail };
      newDetail.invoices = invoices;
      this.invoiceDetail = newDetail;
    }
  }

  @Action
  public async updateChildRecrod({
    childId,
    year,
    month,
    monthlyAdditionalFeeDiff,
    tempNurseryFeeDiff,
    monthlyNurseryFeeDiff,
    spotAdditionalFeeDiff,
    foodServiceFeeDiff,
    extraServiceDiffs,
  }: {
    childId: number;
    year: string;
    month: string;
    monthlyNurseryFeeDiff: number;
    tempNurseryFeeDiff: number;
    monthlyAdditionalFeeDiff: number;
    spotAdditionalFeeDiff: number;
    foodServiceFeeDiff: number;
    extraServiceDiffs: Array<{
      extraServiceBillingDetailId: number;
      adjustmentAmount: number;
    }>;
  }) {
    try {
      const response = await ApiInvoice.updateChildRecord({
        childId,
        year,
        month,
        monthlyAdditionalFeeDiff,
        tempNurseryFeeDiff,
        monthlyNurseryFeeDiff,
        spotAdditionalFeeDiff,
        foodServiceFeeDiff,
        extraServiceDiffs,
      });
      this.refreshChildInvoice(response);
    } catch {
      return;
    }
  }

  // @Action
  // public async saveStatus({
  //   status,
  //   year,
  //   month,
  // }: {
  //   status: string;
  //   year: string | number;
  //   month: string | number;
  // }) {
  //   if (this.invoiceDetail) {
  //     const nurseryId = this.invoiceDetail.nurseryId;
  //     try {
  //       const response = await ApiInvoice.updateStatus({
  //         nurseryId,
  //         status,
  //         year,
  //         month,
  //       });
  //       this.setInvoiceDetail(response);
  //       return response;
  //     } catch {
  //       return null;
  //     }
  //   }
  // }

  @Action({ rawError: true })
  public async updateStatus(input: {
    nurseryIds: number[];
    status: string;
    year: string | number;
    month: string | number;
  }) {
    const response = await ApiInvoice.updateStatus(input).catch();
    if (response) {
      /**
       * TODO: set values
       */
    }
    return response;
  }

  @Action
  public async importRecievables({
    companyId,
    year,
    month,
    filePath,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
    filePath: string;
  }) {
    try {
      const response = await ApiInvoice.importRecievables({
        companyId,
        year,
        month,
        filePath,
      });
      if (response) {
        this.setUploadId(response.importStatusId);
      }
    } catch {
      return;
    }
  }

  @Mutation
  public setUploadId(uploadId: number) {
    this.uploadId = uploadId;
    this.importStatus = null;
  }

  @Action
  public async checkImportStatus() {
    if (this.uploadId) {
      try {
        const response = await ApiInvoice.getUploadStatus({ importStatusId: this.uploadId });
        if (response) {
          this.setUploadStatus(response.status);
        }
      } catch {
        return;
      }
    }
  }

  @Mutation
  public setUploadStatus(status: string) {
    this.importStatus = status;
  }

  // export
  @Action
  public async exportSales({
    companyId,
    year,
    month,
  }: {
    companyId: number;
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiInvoice.exportSales({
        companyId,
        year,
        month,
      });
      if (response) {
        this.setDownloadId(response.exportStatusId);
      }
    } catch (e) {
      return;
    }
  }

  @Mutation
  public setDownloadId(donwloadId: number | null) {
    this.donwloadId = donwloadId;
    this.exportStatus = null;
  }

  @Action
  public async checkExportStatus() {
    if (this.donwloadId) {
      try {
        const response = await ApiInvoice.getExportStatus({ exportStatusId: this.donwloadId });
        if (response) {
          this.setExportStatus(response.status);
          if (response.url) {
            window.open(response.url);
          }
        }
      } catch {
        return;
      }
    }
  }

  @Mutation
  public setExportStatus(status: string) {
    this.exportStatus = status;
  }
}

export const schoolInvoiceModule = getModule(SchoolInvoiceModule);
