/**
 * サイドメニューの項目
 */

type NavMenu = {
  name: string;
  features: {
    slug: string;
    path?: string;
    target?: string;
  }[];
};

export const navMenuItems: NavMenu[] = [
  {
    name: "請求",
    features: [{ slug: "operation-web-invoice-list", path: "/invoice" }],
  },
  {
    name: "施設",
    features: [{ slug: "operation-web-school-list", path: "/school" }],
  },
  {
    name: "運営アカウント",
    features: [
      { slug: "operation-web-management-company", path: "/admin-account" },
      { slug: "operation-web-management-user", path: "/admin-user" },
      { slug: "operation-web-management-accounting", path: "/accounting-setting" },
    ],
  },
  {
    name: "MEMORU",
    features: [
      { slug: "operation-web-memoru-sales", path: "/memoru/sales" },
      { slug: "operation-web-memoru-price-setting", path: "/memoru/setting" },
    ],
  },
  {
    name: "指導計画",
    features: [
      { slug: "operation-web-guidance-plan-form-setting", path: "/guidance/form" },
      { slug: "operation-web-guidance-plan-form-item-setting", path: "/guidance/form-item" },
    ],
  },
];
